import "./About.css";
import AboutUs from "../../assets/images/about-us.png";
import landingSeparator from "../../assets/images/landing-separator-1.png";

const About = () => {
  return (
    <div>
      <div class="">
        <img
          src={AboutUs}
          className="about-us-image"
          width={"100%"}
          alt="contact-img"
        />
      </div>
      <div className="heading_div mt-4">
        <h1>About Us </h1>{" "}
        <img className=" " src={landingSeparator} alt="LandingLogo3" />
      </div>
      <div className=" about-us-content d-flex justify-content-center align-items-center ">
        <p>
          Welcome to Coffee Gable
          <br />
          <br />
          At Coffee Gable, we believe that coffee is more than just a beverage;
          it's an experience, a culture, and a way of life. With a deep-rooted
          love for coffee, we have embarked on a journey to bring you the finest
          selection of coffee beans, curated and roasted to perfection. We
          understand that coffee is more than just a drink – it's a catalyst for
          connection, inspiration, and discovery. Whether you're a seasoned
          coffee connoisseur or just beginning your coffee journey, we invite
          you to join us in celebrating the art and craft of coffee. Shop Our
          Range Of Extremely High-Quality Coffee Beans, Premium Coffee, And Some
          Cool Coffee Brewing Gear.
          <br />
          <br />
          Here's to great coffee and unforgettable moments!{" "}
        </p>
      </div>
    </div>
  );
};

export default About;
