import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container flex-column " style={{}} id="container">
      <h1 className="text-center">Privacy Policy</h1>
      <div dir="ltr">
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          At Coffee Gable, We are dedicated to safeguarding the privacy and
          security of our customers' personal information. This Privacy Policy
          outlines how we collect, use, store, and protect the information you
          provide when using our website{" "}
          <a
            href="http://www.coffeegable.com"
            target="_blank"
            data-saferedirecturl="https://www.google.com/url?hl=en-GB&amp;q=http://www.coffeegable.com&amp;source=gmail&amp;ust=1728485383592000&amp;usg=AOvVaw2Jd--oz5Lo4ztB2TnWpRUo"
          >
            www.coffeegable.com
          </a>
          . By accessing or purchasing from the Site, you consent to the
          practices outlined below.
        </p>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          We collect the following types of information from you when you use
          the Site:
        </p>
        <ul style={{ paddingLeft: 10, fontSize: 18, marginBottom: 0 }}>
          <li style={{ listStyle: "disc" }}>
            <strong>Personal Identification Information:</strong> Name, email
            address, phone number, shipping address, billing address, and other
            necessary details to complete your order.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Payment Information:</strong> Payment card details and
            billing information. We use third-party payment processors and do
            not store your credit card information.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Order Information:</strong> Information about your
            purchases, including the products ordered, order history, and
            transaction details.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Account Information:</strong> If you create an account, we
            collect login credentials (username and password).
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Technical Information:</strong> IP address, browser type,
            operating system, referring URLs, and browsing behavior on the Site
            via cookies or similar technologies.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Communication Information:</strong> If you contact us, we
            may collect your message content and communication preferences.
          </li>
        </ul>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          <span style={{ fontWeight: "normal" }}>
            We use the collected information to:
          </span>
          <br />
        </p>
        <ul style={{ paddingLeft: 10, fontSize: 18 }}>
          <li style={{ listStyle: "disc" }}>
            Process and fulfill your orders, including shipping and delivery.
          </li>
          <li style={{ listStyle: "disc" }}>
            Communicate with you regarding your order status, product updates,
            and customer service inquiries.
          </li>
          <li style={{ listStyle: "disc" }}>
            Improve your shopping experience by personalizing product
            recommendations and marketing offers.
          </li>
          <li style={{ listStyle: "disc" }}>
            Administer your account and provide access to account-related
            functionalities.
          </li>
          <li style={{ listStyle: "disc" }}>
            Enhance the functionality and performance of the Site.
          </li>
          <li style={{ listStyle: "disc" }}>
            Comply with legal obligations and enforce our terms and conditions.
          </li>
        </ul>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          We take reasonable measures to protect your personal data from
          unauthorized access, nonelosure, alteration, or destruction. Our
          security measures include:
        </p>
        <ul style={{ paddingLeft: 10, fontSize: 18 }}>
          <li style={{ listStyle: "disc" }}>
            <strong>Encryption:</strong> All payment transactions are processed
            through secure encryption protocols to protect your financial data
            during transmission.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Access Controls:</strong> Access to personal data is
            restricted to authorized personnel only, and these individuals are
            required to follow strict confidentiality guidelines.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Data Minimization:</strong> We collect only the data
            necessary for the purposes outlined in this policy.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Third-Party Security:</strong> We ensure that any
            third-party service providers handling personal information (such as
            payment processors) meet stringent data protection and security
            standards.
          </li>
        </ul>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          We do not sell or rent your personal data to third parties. However,
          we may share your information with:
        </p>
        <ul style={{ paddingLeft: 10, fontSize: 18 }}>
          <li style={{ listStyle: "disc" }}>
            <strong>Service Providers:</strong> To third-party vendors that
            assist us in operating the Site, processing payments, fulfilling
            orders, or conducting marketing and customer service activities.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Legal Requirements:</strong> In response to lawful requests
            by public authorities, including to meet national security or law
            enforcement requirements, or if required by law.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of all or a portion of our assets, your
            personal data may be part of the transferred assets.
          </li>
        </ul>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          We use cookies and similar tracking technologies to:
        </p>
        <ul style={{ paddingLeft: 10, fontSize: 18 }}>
          <li style={{ listStyle: "disc" }}>
            Improve your browsing experience on the Site.
          </li>
          <li style={{ listStyle: "disc" }}>
            Remember your preferences and personalize content.
          </li>
          <li style={{ listStyle: "disc" }}>
            Analyze site traffic and usage patterns.
          </li>
        </ul>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          You can modify your browser settings to refuse or disable cookies.
          However, some features of the Site may not function properly if
          cookies are disabled.
        </p>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          Depending on your jurisdiction, you may have the following rights
          concerning your personal data:
        </p>
        <ul style={{ paddingLeft: 10, fontSize: 18 }}>
          <li style={{ listStyle: "disc" }}>
            <strong>Access:</strong> Request a copy of the personal data we hold
            about you.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Correction:</strong> Request corrections to any inaccurate
            or incomplete data.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Deletion:</strong> Request the deletion of your personal
            data, subject to legal or contractual obligations.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Objection:</strong> Object to our processing of your
            personal data for marketing purposes.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Portability:</strong> Request that we transfer your data to
            another service provider, where applicable.
          </li>
        </ul>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          To exercise these rights, please contact us. We may need to verify
          your identity before processing your request.
        </p>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          We retain your personal information only as long as necessary to
          fulfill the purposes outlined in this Privacy Policy or as required by
          law. This includes keeping data for legal, accounting, or reporting
          requirements.
        </p>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          Our Site is not intended for use by individuals under the age of 16.
          We do not knowingly collect personal information from children. If we
          become aware that we have collected personal information from a child
          without verification of parental consent, we will take steps to delete
          that information.
        </p>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices, legal requirements, or for other operational, legal,
          or regulatory reasons. We encourage you to review this policy
          periodically. Any changes will be posted on this page, and the
          "Effective Date" will be updated accordingly.
        </p>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          If you have any questions or concerns about this Privacy Policy or how
          your personal information is handled, please contact us at:
        </p>
        <p style={{ fontSize: 18, marginBottom : 0 }}>
          Coffee Gable
          <br />
          Email:{" "}
          <a href="mailto:coffeegable@gmail.com" target="_blank">
            coffeegable@gmail.com
          </a>
         
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
