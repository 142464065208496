import React from "react";

const ReturnPolicy = () => {
  return (
    <div className="container flex-column " style={{ gap: 10 }} id="container">
      <div dir="ltr">
        <h2 style={{fontSize: 24}}>
          <strong>NO Return Policy for Coffee and Coffee Beans</strong>
        </h2>
        <p style={{fontSize: 15, marginBottom : 0 }}>
          All Sales Are Final
        </p>
        <p style={{fontSize: 15, marginBottom : 0 }}>
          At Coffee Gable, we strive to provide the highest quality
          coffee,&nbsp; beans, and accessories.
        </p>
        <p style={{fontSize: 15, marginBottom : 0 }}>
          All sales of coffee and coffee beans are final, No returns or
          exchanges.
        </p>
        <p style={{fontSize: 15, marginBottom : 0 }}>
          If you receive a damaged or incorrect order, please contact our
          customer service team within 24 hours of delivery.
        </p>
        <p style={{fontSize: 24, marginTop: 10, marginBottom: 0}}> <strong>Return Policy for Brewing Equipment </strong></p>
        <p style={{fontSize: 15, }}>
          we want you to be fully satisfied with your brewing equipment
          purchases. If you are not completely happy with your order, please
          review our return policy below.
        </p>
        <p style={{fontSize: 24,marginTop: 10, marginBottom: 0}}>
          <strong>Eligibility for Returns</strong>
        </p>
        <p style={{fontSize: 15, marginBottom: 0}}>
          We accept returns on unused, undamaged brewing equipment within 7
          <strong>&nbsp;days</strong>&nbsp;of purchase. The item must be in its
          original packaging, including all accessories and manuals, and in the
          same condition as when you received it.
        </p>
        <ul style={{fontSize: 15, paddingLeft : 10}}>
          <li style={{ listStyle: "disc" }}>
            <strong>Eligible items</strong>: Brewing machines, grinders, Steel
            Coffee filters.
          </li>
          <li style={{ listStyle: "disc" }}>
            <strong>Non-returnable items</strong>: Used equipment or any items
            damaged due to improper handling.
          </li>
        </ul>
        <div>
          <p style={{fontSize: 24,marginTop: 10, marginBottom: 0}}>
            <strong>How to Initiate a Return</strong>
          </p>
          <p style={{fontSize: 15, }}>
            To initiate a return, please whatsapp or contact our Customer
            Service team within 7 days of receiving your order. Provide your
            order number, and we will guide you through the return process.
          </p>
          <p style={{fontSize: 24,marginTop: 10, marginBottom: 0}}>
            <strong>Return Shipping</strong>
          </p>
          <ul style={{fontSize: 15, paddingLeft : 10}}>
            <li style={{ listStyle: "disc" }}>
              <strong>Customer responsibility</strong>: The cost of return
              shipping will be the responsibility of the customer, except in
              cases where the product was damaged or defective upon arrival.
            </li>
            <li style={{ listStyle: "disc" }}>
              <strong>Damaged or defective products</strong>: If you received a
              damaged or defective item, we will provide a prepaid return
              shipping label and either a replacement or full refund, including
              original shipping costs.
            </li>
          </ul>
          <div>
            <p style={{fontSize: 24,marginTop: 10, marginBottom: 0}}>
              <strong>Refund Process</strong>
            </p>
            <p style={{fontSize: 15, marginBottom: 0}}>
              Once we receive your returned item and confirm it is in its
              original condition, we will process your refund within&nbsp;
              <strong>5-7 business days</strong>. Refunds will be issued to the
              original payment method used during the purchase.
            </p>
            <p style={{fontSize: 15, marginBottom: 0}}>
              <strong>Refund exclusions</strong>: Original shipping costs are
              non-refundable unless the return is due to our error (damaged or
              incorrect items).
            </p>
            <div>
              <p style={{fontSize: 24,marginTop: 10, marginBottom: 0}}>
                <strong>Exchanges</strong>
              </p>
              <p style={{fontSize: 15, }}>
                If you would like to exchange your item for a different model or
                product, please contact us. We will process your exchange once
                we receive the original item.
              </p>
              <p style={{fontSize: 24,marginTop: 10, marginBottom: 0, }}> <strong>Tips for a Smooth Return:</strong></p>
              <ul style={{fontSize: 15, paddingLeft : 10}}>
                <li style={{ listStyle: "disc" }}>
                  Ensure that the item is packed securely to avoid damage during
                  transit.
                </li>
                <li style={{ listStyle: "disc" }}>
                  Keep proof of return shipping and tracking until your refund
                  is processed.
                </li>
              </ul>
              <div>
                We value your satisfaction and are here to help with any
                concerns. For more information, visit our&nbsp;
                <strong>FAQ page</strong>&nbsp;or contact us at&nbsp;
                <strong>
                  <a rel="noopener">support@coffeegable.com</a>
                </strong>
                <br />
              </div>
            </div>
          </div>
          <div>
            <strong>
              <a rel="noopener">
                <br />
              </a>
            </strong>
          </div>
          <div>
            <ul style={{fontSize: 15, paddingLeft : 10}}>
              <li style={{ listStyle: "disc" }}>
                <strong>Return window</strong>: 7 days for unused, undamaged
                items.
              </li>
              <li style={{ listStyle: "disc" }}>
                <strong>Customer covers return shipping</strong>&nbsp;unless the
                product is defective or damaged.
              </li>
              <li style={{ listStyle: "disc" }}>
                <strong>Refund process</strong>: 5-7 business days after receipt
                and inspection.
              </li>
            </ul>
          </div>
          <br />
        </div>
        <p style={{fontSize: 15, marginTop: 10}}>
          <br />
        </p>
      </div>
    </div>
  );
};

export default ReturnPolicy;
