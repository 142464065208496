import React from "react";
import ThankYouImage from "../../assets/images/Thank-you.png";
import { useNavigate } from "react-router-dom";
const OrderConfirmPage = () => {
  const navigate = useNavigate();
  return (
    <section class="login-main-wrapper">
      <div class="main-container">
        <div class="login-process">
          <div class="login-main-container">
            <div class="thankyou-wrapper" style={{ textAlign: "center" }}>
              <h1>
                <img src={ThankYouImage} alt="thanks" height={300} />
              </h1>
              <p>We will get in touch with you soon...</p>
              <button onClick={() => navigate("/menu/coffee")} class="fancy">
                <span class="top-key"></span>
                <span class="text">Back To Home</span>
                <span class="bottom-key-1"></span>
                <span class="bottom-key-2"></span>
              </button>{" "}
              <div class="clr"></div>
            </div>
            <div class="clr"></div>
          </div>
        </div>
        <div class="clr"></div>
      </div>
    </section>
  );
};

export default OrderConfirmPage;
