import "./Menu.css";

import ProductCard from "./ProductCard/ProductCard";
import { useCallback, useEffect, useState } from "react";
import { productsData } from "../../Helper/_helper";
import { useLocation, useParams } from "react-router-dom";
import { allCategoryApi, allProductByCategoryIdApi } from "../../libs/api";
import { useToasts } from "react-toast-notifications";
import Loader from "../../Component/Loader/Loader";

const Menu = () => {
  const params = useParams();
  const location = useLocation();
  const { addToast } = useToasts();
  const [step, setStep] = useState(
    params?.categoryMenu === "coffee" ? "step1" : "step2"
  );
  const [products, setProducts] = useState();
  const [categoryList, setCategoryList] = useState();
  console.log("categoryList: ", categoryList);
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [filterProductData, setFilterProductData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  console.log("selectedCategoryId: ", selectedCategoryId);

  // useEffect(() => {
  //   if (products || filterState) {
  //     let filteredData = products?.filter((er) => er?.category === filterState);
  //     setFilterProductData(filteredData);
  //     setSearch("");
  //   }
  // }, [products, filterState]);

  const callProductAPI = useCallback(
    async (id) => {
      try {
        const apiCall = await allProductByCategoryIdApi(id);
        console.log("apiCall: ", apiCall);
        if (apiCall.status === 200) {
          setFilterProductData(apiCall?.data?.rows);
          setProducts(apiCall?.data?.rows);
          setSearch("");
          setLoading(false);
        } else {
          setLoading(false);
          addToast(apiCall.err_msg, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        addToast(error, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    },
    [addToast]
  );

  // useEffect(() => {
  //   if (selectedCategoryId) {

  //   }
  // }, [selectedCategoryId]);

  const callAPI = useCallback(async () => {
    try {
      const apiCall = await allCategoryApi();
      console.log("apiCall: ", apiCall);
      if (apiCall.status === 200) {
        setCategoryList(apiCall?.data?.rows);
        setLoading(false);
      } else {
        addToast(apiCall.err_msg, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.log(error);
      addToast(error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [addToast]);

  useEffect(() => {
    callAPI();
  }, [callAPI]);

  useEffect(() => {
    if (location?.state) {
      setStep("step2");
      callProductAPI(location?.state);
      setSelectedCategoryId(location?.state);
    }
  }, [location?.state]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      {/*   <div className="search-box">
        <div class="form-group has-search">
          <div class="searchbar">
            <div class="searchbar-wrapper">
              <div class="searchbar-left">
                <div class="search-icon-wrapper">
                  <span class="search-icon searchbar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                    </svg>
                  </span>
                </div>
              </div>

              <div class="searchbar-center">
                <div class="searchbar-input-spacer"></div>

                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  class="searchbar-input"
                  maxlength="2048"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {step === "step1" && params?.categoryMenu === "coffee" && (
        <div>
          <div className="container" id="container">
            {categoryList?.map((item, i) =>
              item?.comingSoon ? (
                <div class="flip-card">
                  <div class="flip-card-front text-center">
                    <p>{item?.categoryName}</p>
                    <img
                      style={{ objectFit: "cover" }}
                      src={item?.image}
                      alt={i}
                    />
                  </div>
                </div>
              ) : (
                <div key={i} class="flip-card">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <p>{item?.categoryName}</p>
                      <img
                        style={{ objectFit: "cover" }}
                        src={item?.image}
                        alt={i}
                      />
                    </div>
                    <div class="flip-card-back">
                      <p>{item?.categoryName}</p>

                      {/* <div className="flip-card-back-price-div">
                    <p>Price : </p> &nbsp;&nbsp;&nbsp;
                    <p>{`₹0`}</p>
                  </div> */}
                      <div
                        className="flip-card-back-btn-div d-flex justify-content-center"
                        style={{ height: "100%" }}
                      >
                        {" "}
                        <button
                          className="quick-view-btn"
                          onClick={() => {
                            setStep("step2");
                            setLoading(true);
                            callProductAPI(item?.id);
                            setSelectedCategoryId(item?.id);
                          }}
                        >
                          <div class="default-btn">
                            <svg
                              class="css-i6dzq1"
                              stroke-linejoin="round"
                              stroke-linecap="round"
                              fill="none"
                              stroke-width="2"
                              stroke="#FFF"
                              height="20"
                              width="20"
                              viewBox="0 0 24 24"
                            >
                              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                              <circle r="3" cy="12" cx="12"></circle>
                            </svg>
                            <span style={{ fontSize: 16 }}>Quick View</span>
                          </div>{" "}
                          <div class="hover-btn">
                            <svg
                              class="css-i6dzq1"
                              stroke-linejoin="round"
                              stroke-linecap="round"
                              fill="none"
                              stroke-width="2"
                              stroke="#fff"
                              height="20"
                              width="20"
                              viewBox="0 0 24 24"
                            >
                              <circle r="1" cy="21" cx="9"></circle>
                              <circle r="1" cy="21" cx="20"></circle>
                              <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                            </svg>
                            <span style={{ fontSize: 16 }}>Shop Now</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}

      {step === "step2" && (
        <div>
          <div className="container" id="container">
            {filterProductData &&
              filterProductData.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}

            {filterProductData?.length === 0 && (
              <div class="wrapper mt-5">
                <h1>
                  No Products Found<span class="dot"></span>
                </h1>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
